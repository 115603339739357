import api from "./api";
import emitter from "../common/emitter";

//Used for getting and setting current user profile data
class ProfileService {

    BASE_PATH = 'moderator/profile';

    getRemote() {
        return api
            .get(this.BASE_PATH)
            .then(response =>
            {
                if (response.data.response.results)
                {
                    //Profile
                    localStorage.setItem("profile", JSON.stringify(response.data.response.results));
                }

                return response.data;
            });
    }

    getLocal()
    {
        return JSON.parse(localStorage.getItem("profile"));
    }

    update(user) {
        return api
            .put(this.BASE_PATH,user)
    }

    changePassword(oldPassword,newPassword)
    {
        return api
            .put(this.BASE_PATH+'/password', {cp:oldPassword,np:newPassword}).then((response)=>
            {

            })
    }
    updateAvatar(formData)
    {
        return api
            .post(this.BASE_PATH+'/avatar',formData,
                {
                    headers:
                    {
                        'Content-Type': 'multipart/form-data'
                    }
                })
    }


    setGCM(gcmToken)
    {
        return api
            .post('/moderator/set_gcm', {
                gcm_token: gcmToken,
                device_type: "android"
            });
    }
}

export default new ProfileService();
