import api from "../api";
import TokenService from "./token.service";

class AuthService {
    login(user) {
        return api
            .post('/auth/moderator/login', {
                email: user.email,
                password: user.password
            })
            .then(response =>
            {
                //console.log(response);
                if (response.data.token)
                {
                    TokenService.setUser(response.data);
                }

                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
    }
}

export default new AuthService();
