import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth.module';
import { events } from './events.module';
import { panels } from './panels.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        events,
        panels
    }
});