import axiosInstance from "../api";
import TokenService from "./token.service";
import emitter from "../../common/emitter";

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                //config.headers["x-access-token"] = token; // for Node.js Express back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if ((originalConfig.url !== "/auth/admin/login" || originalConfig.url !== "/auth/admin/refresh")&& err.response)
            {
                // Access Token was expired
                console.log(err.response.data.error.code )
                if (err.response.data.error.code === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await axiosInstance.get("/auth/admin/refresh", {
                            refreshToken: TokenService.getLocalRefreshToken(),
                        });

                        const { accessToken } = rs.data.token;

                        store.dispatch('auth/refreshToken', rs.data.token);
                        TokenService.updateLocalAccessToken(rs.data.token);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        emitter.emit("logout");
                        //return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;
