export const panels = {
    namespaced: true,
    state: {
        page: 1
    },
    mutations: {
        updatePage(state, page) {
            state.page = page;
        },
        clearPage(state) {
            state.page = 1;
        }
    },
    actions: {
        changePage(context, page) {
            context.commit('updatePage', page)
        },
        refreshPage(context) {
            context.commit('clearPage')
        },
    }
};
