import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

const firebaseConfig = {
    apiKey: "AIzaSyAV1nf5krHTCHm5pO7WPvLfjp1ZL6QmJag",
    authDomain: "pinevento.firebaseapp.com",
    projectId: "pinevento",
    storageBucket: "pinevento.appspot.com",
    messagingSenderId: "998145107153",
    appId: "1:998145107153:web:75156314157f32d88c11f0",
    measurementId: "G-3CX479ZJT5"
};

firebase.initializeApp(firebaseConfig)
export default firebase.messaging()