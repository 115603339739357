import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router);

let router = new Router({
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  mode: "history",
  routes: [
    {
      path: '/login',
      meta: { layout: 'userpages' },
      component: () => import('../views/Login.vue'),
    },
    {
      path: '/profile',
      component: () => import('../views/Profile.vue'),
    },
    {
      path: '/events',
      component: () => import('../views/Events.vue'),
      beforeEnter: (to, from, next) => {
        if (!from.path.startsWith("/event")) {
          store.dispatch('events/deleteFilters')
        }
        next();
      },
    },
    {
      path: '/event/:eventId/panels',
      component: () => import('../views/Events/EventPanels.vue'),
      name: 'panels',
      props: true,
      beforeEnter: (to, from, next) => {
        if (!from.path.match("panel/(.*)/")) {
          store.dispatch('panels/refreshPage')
        }
        next();
      },
    },
    {
      path: '/event/:eventId/panel/:targetId/polls',
      component: () => import('../views/Polls/Polls.vue'),
      name: 'panel-polls',
      props: true,
    },
    {
      path: '/event/:eventId/panel/:targetId/qna',
      component: () => import('../views/QnA/QnASessions.vue'),
      name: 'panel-qna',
      props: true,
    },
    {
      path: '/event/:eventId/users',
      component: () => import('../views/Events/EventUsers.vue'),
      name: 'event-users',
      props: true
    },
    {
      path: '/event/:targetId/polls',
      component: () => import('../views/Polls/Polls.vue'),
      name: 'event-polls',
      props: true,
    },
    {
      meta: { layout: 'userpages' },
      path: '/polls/:pollId/results/columns',
      component: () => import('../views/Polls/PollsResultsStandaloneColumn.vue'),
      name: 'event-poll-answers-standalone-column',
      props: true,
    },
    {
      meta: { layout: 'userpages' },
      path: '/polls/:pollId/results/donut',
      component: () => import('../views/Polls/PollsResultsStandaloneDonut.vue'),
      name: 'event-poll-answers-standalone-donut',
      props: true,
    },
    {
      path: '/event/:targetId/qna',
      component: () => import('../views/QnA/QnASessions.vue'),
      name: 'event-qna',
      props: true,
    },
    {
      path: '/event/qna/session/:sessionId',
      component: () => import('../views/QnA/QnASessionMessages.vue'),
      name: 'qna-session',
      props: true,
    },
    {
      meta: { layout: 'userpages' },
      path: '/event/qna/session/external/:sessionId',
      component: () => import('../views/QnA/QnASessionMessagesExternal.vue'),
      name: 'qna-session-external',
      props: true,
    },
  ]
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  //console.log(loggedIn);
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
