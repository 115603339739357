import Vue from 'vue'
import Vuex from 'vuex';
import router from './router'
import store from './store';
import vuetify from './plugins/vuetify';
import App from './App.vue'
//HTTP client
import axios from 'axios';
import VueAxios from 'vue-axios'
//Theme
import BootstrapVue from "bootstrap-vue"
import Default from './Layout/Wrappers/baseLayout.vue';
import Pages from './Layout/Wrappers/pagesLayout.vue';
import Apps from './Layout/Wrappers/appLayout.vue';
//Editor
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import setupInterceptors from './services/auth/setupInterceptors';

import Vuelidate from 'vuelidate'
// Import the functions you need from the SDKs you need
import firebaseMessaging from './firebase'

Vue.prototype.$messaging = firebaseMessaging
Vue.prototype.$vapidKey = "BA-v0ha5I7iIXBc6wSjhkoUB_B9QXaqHRdAHcfbE2ONuOHI1OILb6Cf-QJY5LtDQ4H4hbyxQxjNsami8onRHlcc"
Vue.prototype.$firbaseToken = ""

if ('serviceWorker' in navigator)
{
    navigator.serviceWorker.register('firebase-messaging-sw.js')
        .then(reg => {
            Vue.prototype.$messaging.getToken({ vapidKey: Vue.prototype.$vapidKey  }).then((currentToken) => {
                if (currentToken) {
                    Vue.prototype.$firbaseToken = currentToken
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            })
            console.log(`Service Worker Registration (Scope: ${reg.scope})`);
        })
        .catch(error => {
            const msg = `Service Worker Error (${error})`;
            console.error(msg);
        });
}
else {
    // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
    console.warn('Service Worker not available');
}

window.Vue = require('vue').default;

Vue.config.productionTip = false;
Vue.router = router;

Vue.use(VueAxios, axios)
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(VueQuillEditor);
Vue.use(Vuelidate)

setupInterceptors(store);

Vue.component('default-layout', Default);
Vue.component('userpages-layout', Pages);
Vue.component('apps-layout', Apps);

Vue.prototype.$googleMapsKey = "AIzaSyAZV7KXKyQXDQ2M_1j5cPFPuoxXlyt7xK0"
//Vue.prototype.$hostname = "http://localhost:8000"
//Vue.prototype.$hostname = "https://pinevento.alexdesign.eu"
//Vue.prototype.$hostname = "https://apps.pinevento.com"
Vue.prototype.$hostname = "https://apps.pinevento.com"
Vue.prototype.$hostnameImages = "https://apps.pinevento.com"

const app = new Vue({
    router,
    store,
    vuetify,
    template: '<App/>',
    render: h => h(App)
}).$mount('#app');