<template>
  <div>
    <div class="search-wrapper" v-bind:class="{ active: searchOpen }">
      <div class="input-holder">
        <input type="text" ref="searchbox" class="search-input" v-model="query" :placeholder=hint v-on:keyup.enter="search()"/>
        <button class="search-icon" v-on:click="search()">
          <span />
        </button>
      </div>
      <button class="close" v-on:click="close()" />
    </div>
  </div>
</template>

<script>
import emitter from "../../../common/emitter";

export default
{
  data() {
    return {
      searchOpen: false,
      query:""
    };
  },
  props: {
    hint: String
  },
  mounted() {
    emitter.on("searchCloseRemote", (e) =>
    {
      this.close(true);
    });

    emitter.on("searchOpenRemote", data => this.open(data));
  },
  beforeDestroy()
  {
    emitter.off('searchCloseRemote');
  },
  methods:{
  search()
  {
    if(this.searchOpen)
    {
      emitter.emit("search",{query:this.query});
    }
    else
    {
      this.$refs.searchbox.focus()
      this.searchOpen = true
    }
  },
    close(isRemote)
    {
      if(!isRemote)
      {
        emitter.emit("searchClose",{query:this.query});
      }
      this.searchOpen = false
      this.query = ""
    },
    open(query)
    {
      this.searchOpen = true
      this.query = query
    }
  }
};
</script>

