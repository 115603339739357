<template>
  <div class="app-container app-theme-white">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
