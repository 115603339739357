<template>
  <div class="d-flex">
    <div class="header-btn-lg pr-0">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
              <v-avatar size="42" color="var(--primary)" style="cursor: pointer" v-on:click="profile()" >
                <img width="42" :src="imageHostname+'/storage/images/avatars/user/'+currentUser.avatar" :alt="currentUser.name" :hidden="!hasAvatar(currentUser.avatar)" />
                <span class="white--text h4" :hidden="hasAvatar(currentUser.avatar)" style="padding-top: 6px" >{{currentUser.name.substring(0,1).toUpperCase()}}</span>
              </v-avatar>
          </div>
          <div class="widget-content-left ml-3 header-user-info">
            <div class="widget-heading">{{currentUser.name}}</div>
            <div class="widget-subheading">{{currentUser.job_position}}</div>
          </div>
          <div class="widget-content-right header-user-info ml-3">
            <b-btn v-b-tooltip.hover title="Изход"
              class="btn-shadow p-1" size="sm" variant="danger" v-on:click="logout()">
              <font-awesome-icon icon="times" class="mr-1 ml-1" />
            </b-btn>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueCircle from "vue2-circle-progress";
import Trend from "vuetrend";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ProfileService from "../../../services/profile.service";
import emitter from "../../../common/emitter";

library.add(
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH,
    faTimes
);

export default {
  components: {
    VuePerfectScrollbar,
    "font-awesome-icon": FontAwesomeIcon,
    VueCircle,
    trend: Trend
  },
  data() {
    return {
      imageHostname: Vue.prototype.$hostnameImages,
      fill1: { gradient: ["#00b09b", "#96c93d"] },
      fill2: { gradient: ["#ff0844", "#ffb199"] },
      fill3: { gradient: ["#f6d365", "#fda085"] },
      showDrawerSection: false,
      currentUser: this.getCurrentUser()
    };
  },
  methods: {
    getCurrentUser() {
      var user = ProfileService.getLocal()
      if (user != null) {
        return user;
      } else {
        user = new Object();
        user.name = "";
        user.avatar = "";
        user.job_position = "";
        return user;
      }
    },
    logout() {
      emitter.emit("logout");
    },
    profile() {
      this.$router.push('/profile');
    },
    hasAvatar(avatar)
    {
      if(!avatar)
      {
        return false
      }
      else
      {
        if(avatar.endsWith(".webp"))
        {
          return true
        }
        else
        {
          return false;
        }
      }
    },
  },
    mounted() {
      emitter.on("updateProfile", (e) =>
      {
        console.log("updated")
        this.currentUser = this.getCurrentUser()
        this.$forceUpdate()
      });
    },
    beforeDestroy() {
      emitter.off('updateProfile');
    }
};
</script>
