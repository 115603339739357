import axios from "axios";

const instance = axios.create({
    //baseURL: "http://localhost:8000/api",
    //baseURL: "https://pinevento.alexdesign.eu/api",
    //baseURL: "https://apps.pinevento.com/api",
    baseURL: "https://apps.pinevento.com/api",
    headers: {
        "Content-Type": "application/json",
    },
});

export default instance;
