export const events = {
    namespaced: true,
    state: {
        filtered: false,
        status: -1,
        public: false,
        startDate: "",
        endDate: "",
        search: "",
        page: 1,
        view: 'list',
        calendarStartDate: "",
        calendarEndDate: "",
        selectedCalendarDate: new Date().toISOString().slice(0,10)
    },
    mutations: {
        updateFilters(state, filters) {
            state.filtered = true;
            state.status = filters.status;
            state.public = filters.public;
            state.startDate = filters.startDate;
            state.endDate = filters.endDate;
            state.search = filters.search;
            state.page = filters.page;
        },
        updatePage(state, page) {
            state.page = page;
        },
        updateSearch(state, search) {
            state.search = search;
        },
        clearFilters(state) {
            state.filtered = false;
            state.status = -1;
            state.public = false;
            state.startDate = "";
            state.endDate = "";
            state.search = "";
            state.page = 1;
            state.view = 'list';
            state.calendarStartDate = "";
            state.calendarEndDate = "";
            state.selectedCalendarDate = new Date().toISOString().slice(0,10);
        },
        clearPage(state) {
            state.page = 1;
        },
        clearSearch(state) {
            state.search = "";
        },
        setView(state, view) {
            state.view = view;
        },
        setCalendarStart(state, start) {
            state.calendarStartDate = start;
        },
        setCalendarEnd(state, end) {
            state.calendarEndDate = end;
        },
        setSelectedCalendarDate(state, date) {
            state.selectedCalendarDate = date;
        }
    },
    actions: {
        addFilters(context, filters) {
            context.commit('updateFilters', filters)
        },
        changePage(context, page) {
            context.commit('updatePage', page)
        },
        changeSearch(context, search) {
            context.commit('updateSearch', search)
        },
        deleteFilters(context) {
            context.commit('clearFilters')
        },
        refreshPage(context) {
            context.commit('clearPage')
        },
        refreshSearch(context) {
            context.commit('clearSearch')
        },
        changeView(context, view) {
            context.commit('setView', view)
        },
        changeCalendarStart(context, start) {
            context.commit('setCalendarStart', start)
        },
        changeCalendarEnd(context, end) {
            context.commit('setCalendarEnd', end)
        },
        changeSelectedCalendarDate(context, date) {
            context.commit('setSelectedCalendarDate', date)
        }
    }
};
