<template>
  <div id="app">
    <component :is="layout">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </component>
  </div>
</template>

<script>
const default_layout = "default";
import emitter from "./common/emitter";

export default
{
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    },
  },
    methods: {
      logout() {
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      }
    },
    mounted() {
      emitter.on("logout", (e) =>
      {
        this.logout();
      });

    },
    beforeDestroy() {
      emitter.off('logout');
    },
}
</script>

<style lang="scss">
// @import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "./assets/base";
</style>
